<template>
  <div class="right_cont">
    <div class="title flex_center_between_box">
      <div class="title_txt flex_center_start_box"><img src="../../assets/img/account/title_ic8.png" />Account Settings</div>
      <div class="btn_export flex_center" v-show="isEdit" @click="editForm"><img src="../../assets/img/account/edit_ic.png" />Edit</div>
      <div class="btn_export btn_save flex_center" v-show="!isEdit" @click="submitForm"><img src="../../assets/img/account/btn_save.png" />Save</div>
    </div>
    <div class="right_cont_box">
      <el-form ref="settingForm" label-position="left" :hide-required-asterisk="true" :model="settingForm" :rules="settingFormRules" label-width="206px" class="asset_form">
        <el-form-item label="Account ID:">
          <el-input v-model="settingForm.userCode" disabled></el-input>
        </el-form-item>
        <el-form-item label="Account Name:" prop="accountName">
          <el-input v-model="settingForm.accountName" :disabled="isEdit"></el-input>
        </el-form-item>
        <el-form-item label="Password:" prop="password">
          <el-input v-model="settingForm.password" :disabled="isEdit" :type="inputType" maxlength="20" autocomplete="off">
            <i slot="suffix" class="icon_watch" :class="inputType != 'password' ? 'icon_watchun' : 'icon_watchon'" @click="showconfirmPwd"></i>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { settingAccount } from '@/api/userApi.js'
import crypto from '@/utils/crypto.js';
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Incorrect password format'))
      } else if (value !== this.createAccountForm.password) {
        callback(new Error('Confirm password is inconsistent with password'))
      } else {
        callback()
      }
    }
    return {
      isEdit: true,
      inputType: 'password',
      settingForm: {
        userCode: '',
        accountName: '',
        password: '',
        type: 0,
      },
      settingFormRules: {
        accountName: [{ required: true, message: 'Please enter the account name', trigger: 'blur' }],
        password: [{ required: true, validator: validatePass, trigger: 'blur' }],
      },
    }
  },
  computed: {},
  created() {
    this.setData()
  },
  methods: {
    async setData() {
      let res = await settingAccount(this.settingForm)
      if (res && res.code) {
        this.settingForm.userCode = res.data.userCode
        this.settingForm.accountName = res.data.loginName
        this.settingForm.password = res.data.password

      }
    },
    //编辑
    editForm() {
      this.isEdit = false
    },
    //提交编辑
    async submitForm() {
      this.settingForm.type = 1
      console.log(`this.settingForm`, this.settingForm);
      this.settingForm.password=crypto.encrypt(this.settingForm.password)
      //  
      let res = await settingAccount(this.settingForm)
      if (res && res.code) {
        this.$message({
          message: res.msg,
          center: true,
          type: 'success',
        })
        this.isEdit = true
      }else{
         this.$message({
          message: res.msg,
          center: true,
          type: 'error',
        })
      }
    },
    //点击小眼睛
    showconfirmPwd() {
      if (this.inputType === 'password' && !this.isEdit) {
        this.inputType = 'text'
      } else {
        this.inputType = 'password'
      }
    },
  },
}
</script>
<style scoped>
.right_cont_box {
  padding: 30px;
}
.right_cont {
  margin-top: 12px;
}
.title {
  margin-bottom: 20px;
}
.title_txt {
  font-size: 16px;
  font-family: 'Arial Bold';
}
.title_txt img {
  width: 20px;
  margin-right: 8px;
}

.btn_export {
  margin-right: 0;
  margin-left: auto;
}
.asset_form .el-form-item:not(:last-child) {
  border-bottom: 1px solid #e7eaed;
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.asset_form .el-form-item:last-child {
  margin-bottom: 0;
}
.asset_form /deep/ .el-form-item .el-input__inner {
  border-width: 0;
}
.asset_form /deep/ .el-form-item__error {
  padding-top: 0;
}
.asset_form /deep/ .el-form-item__label {
  color: #8497ab;
}
.asset_form /deep/ .el-input.is-disabled .el-input__inner {
  background-color: #fff;
  color: #022955;
}
.icon_watch {
  width: 30px;
  height: 34px;
  display: inline-block;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.icon_watchun {
  background-image: url(../../assets/img/watch_ic2.png);
}
.icon_watchon {
  background-image: url(../../assets/img/watch_ic1.png);
}
.btn_save {
  background: #e7eaed;
  color: #022955;
}
</style>
